import Vue from 'vue';

export default {
	url: `/api-client/Selection/`,
	api: Vue.prototype.$api,

	getAllSelections(params) {
		return this.api.get(`${this.url}GetAllSelection`, {params});
	},

	getSelectionItem(id) {
		return this.api.get(`${this.url}GetBySelection?id=${id}`);
	},

	getDirectPurchases(params) {
		return this.api.get(`${this.url}GetAllDirectPurchases`, {params});
	},

	getDirectPurchase(id) {
		return this.api.get(`${this.url}GetByDirectPurchases?id=${id}`);
	},

}