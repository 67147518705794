<template>
	<main class="page-container container">
		<header class="auction-header justify-space-between mb-20" :class="isMobileMedium ? 'flex-wrap' : ''">
			<app-text
				tag="h1"
				size="26"
				line-height="34"
				weight="600"
				:class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"
				class="mr-30"
				>
				{{ tenderType === 2 ? $t('selection') : $t('tender')}}
			</app-text>

			<app-input v-model="query.name" @input="load" class="auction-header__input" style="width: 220px" :placeholder="$t('search')">
				<template #prepend>
					<img src="@/assets/icons/search.svg" style="width: 16px" alt="" />
				</template>
			</app-input>
		</header>

		<div class="section__body plan-graph" :class="isMobileSmall ? 'mb-30' : 'mb-60'">

			<template v-if="selections.length > 0">
				<selection-card v-for="(selection, idx) in selections" :key="idx" :data="selection"></selection-card>
			</template>
			<p style="text-align:center" v-else>{{ $t('noData') }}</p>

			<pagination :total="total" :perPage="query.take" @paginate="pagination($event)"> </pagination>
		</div>
	</main>
</template>
<script>
import AppText from "@/components/shared-components/AppText";
import AppButtonA from "@/components/shared-components/AppButtonA";
import FormLabel from "@/components/shared-components/FormLabel";
import AppButton from "@/components/shared-components/AppButton";
import Pagination from "@/components/shared-components/Pagination";
import AppInput from "@/components/shared-components/AppInput";
import SelectionCard from '@/components/pages/selection/SelectionCard';

import SelectionService from '@/services/api/selection-service';

export default {
	name: "index",
	data() {
		return {
			tenderType: null,
			query: {
				tenderType: null,
				name: null,
				skip: 0,
				take: 10
			},
			selections: [],
			total: 0
		};
	},
	components: {
		AppButton,
		FormLabel,
		AppButtonA,
		AppText,
		Pagination,
		AppInput,
		SelectionCard
	},
	created() {
		this.tenderType = this.$store.getters.getTenderType();
		this.load();
	},
	methods: {
		load() {
			this.query.tenderType = this.tenderType;
			SelectionService.getAllSelections(this.query)
			.then(response => {
				this.selections = response.data.result.data;
				this.total = response.data.result.total;
			}, error => console.log(error))
		}
	}
};
</script>
<style lang="scss" scoped>
a {
	text-decoration: underline;
	color: cornflowerblue;
}

.auction-filter {
	display: flex;

	&__item {
		width: #{size(225px, 995px)};
	}

	&__item-large {
		width: #{size(295px, 995px)};
	}

	&__items-wrap {
		width: calc(100% - 200px);
	}

	&__reset-button {
		margin-left: auto;
		margin-bottom: 40px;
		margin-right: -10px;
	}

	&__buttons-wrap {
		padding-top: 26px;
		width: 200px;
	}

	&__buttons {
		display: flex;
		width: 231px;
		margin-left: -31px;
	}
}

.auction-header {
	display: flex;
	align-items: center;

	&__input {
		//	flex-grow: 1;
		margin-right: 15px;
	}

	&__button {
		svg {
			margin-left: -8px;
		}
	}
}

.auction-filter {
	display: flex;

	&__item {
		width: #{size(225px, 995px)};
	}

	&__item-large {
		width: #{size(295px, 995px)};
	}

	&__items-wrap {
		width: calc(100% - 200px);
	}

	&__reset-button {
		margin-left: auto;
		margin-bottom: 40px;
		margin-right: -10px;
	}

	&__buttons-wrap {
		padding-top: 26px;
		width: 200px;
	}

	&__buttons {
		display: flex;
		width: 231px;
		margin-left: -31px;
	}
}

.auction-header {
	display: flex;
	align-items: center;

	&__input {
		//	flex-grow: 1;
		margin-right: 15px;
	}

	&__button {
		svg {
			margin-left: -8px;
		}
	}
}

@media (max-width: 800px) {
	.auction-filter__items-wrap {
		width: 100%;
	}

	.auction-filter__buttons-wrap {
		width: 100%;
	}

	.auction-filter__buttons {
		margin-left: 0;
		width: auto;
	}

	.auction-filter__reset-button {
		margin-bottom: 20px;
	}

	.auction-filter__reset-button {
		margin-left: 0;
	}
}
@media (max-width: 800px) {
	.auction-filter__items-wrap {
		width: 100%;
	}

	.auction-filter__buttons-wrap {
		width: 100%;
	}

	.auction-filter__buttons {
		margin-left: 0;
		width: auto;
	}

	.auction-filter__reset-button {
		margin-bottom: 20px;
	}

	.auction-filter__reset-button {
		margin-left: 0;
	}
}
</style>
