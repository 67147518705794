<template>
	<div class="tender-item mb-20" :class="isMobile ? 'flex-wrap' : ''">
		<div class="tender-item__side-info px-20 py-10" :class="isMobileSmall ? 'w-100' : ''">
			<div class="tender-item__lot-info mb-20">№ лота: {{ data.selectionNumber }}</div>
			<div class="d-flex flex-column">
				<app-text class="color-text-2 mb-5" size="14" line-height="18">Срок окончания</app-text>
				<app-text size="14" line-height="18" weight="600">
					{{ data.endDate | moment("DD-MM-YYYY HH:mm:ss") }}
				</app-text>
			</div>
		</div>
		<div class="tender-item__content px-20 py-10">
			<div
				offset-x="20"
				offset-y="15"
				:count="isMobile ? 1 : 3"
				class="mb-15 d-flex justify-space-between    "
			>
				<div class="tender-item__info-item">
					<app-text class="color-text-2" size="14" line-height="18">Категория: 
						<span v-for="(category, idx) in productData.categories" :key="idx" class="mr-5">{{ category | translate }}</span> &nbsp;
					</app-text>
					<app-text size="14" line-height="18" weight="600">
						<span v-for="(product, idx) in productData.products" :key="idx" class="mr-5">{{ product | translate }}</span> &nbsp;
					</app-text>
				</div>
				<div class="tender-item__link">
					<app-text
						class="link-title link-title--underline color-text-2"
						size="12"
						line-height="16"
						weight="500"
					>
						({{ data.productSchedule.length }}) товары/услуги
					</app-text>
				</div>
			</div>
			<table>
				<tr>
					<th style="min-width: 230px">
						<app-text class="color-text-2 text-left pr-10" size="14" line-height="18"
							>Регион</app-text
						>
					</th>
					<th style="min-width: 90px">
						<app-text class="color-text-2 text-left" size="14" line-height="18">Район</app-text>
					</th>
					<th>
						<app-text class="color-text-2 text-left" size="14" line-height="18">
							Стартовая цена
						</app-text>
					</th>
				</tr>
				<tr>
					<td>
						<div class="tender-item__info-item pr-30">
							<app-text size="14" line-height="18" weight="600">
								{{ data.regionName | translate }}
							</app-text>
						</div>
					</td>
					<td>
						<div class="tender-item__info-item pr-30">
							<app-text class="" size="14" line-height="18" weight="600">
								{{ data.districtName | translate }}
							</app-text>
						</div>
					</td>
					<td>
						<div class="tender-item__info-item pr-30">
							<app-text class="color-text-success" size="16" line-height="18" weight="600">
								{{ data.price | price }} UZS
							</app-text>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div class="tender-item__side-actions px-20 py-10" :class="isMobile ? 'w-100' : ''">
			<router-link :to="`/selection/details/${data.id}`">
				<app-button-a
					class="tender-item__button mb-15"
					:class="isMobile ? 'w-auto' : 'w-100'"
					padding-x="0"
					>Подробнее
				</app-button-a>
			</router-link>
			<app-button-a
				class="tender-item__action mb-15"
				theme="transparent"
				:class="{ 'color-text-light': !isFavorite }"
				text
				flex
				@click="isFavorite = !isFavorite"
			>
				<img
					v-if="isFavorite"
					class="mr-10"
					src="../../../assets/icons/colored/star-filled-main.svg"
					alt=""
				/>
				<img v-if="!isFavorite" class="mr-10" src="../../../assets/icons/star.svg" alt="" />
				<span class="color-text-2">Избранное</span>
			</app-button-a>
			<app-button-a class="tender-item__action color-text-light" text flex theme="transparent">
				<img src="../../../assets/icons/share-arrow.svg" class="mr-10" alt="" />
				<span class="color-text-2">Поделиться</span>
			</app-button-a>
		</div>
	</div>
</template>
<script>
import AppButtonA from '@/components/shared-components/AppButtonA';
import AppText from '@/components/shared-components/AppText';

export default {
	name: 'selection-card',
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	components: {
		AppButtonA,
		AppText
	},
	data() {
		return {
			isFavorite: true
		}
	},
	methods: {
		insertProductData(data, item, fieldName) {
			if (!data[fieldName].includes(item)) {
				data[fieldName].push(item);
			}
		}
	},
	computed: {
		productData() {
			const result = {
				products: [],
				categories: []
			};

			this.data.productSchedule.forEach(item => {
				this.insertProductData(result, item.productName, 'products');
				this.insertProductData(result, item.productCategoryName, 'categories');
			});

			return result;
		}
	}
}
</script>
<style lang="scss" scoped>

.tender-item {
	display: flex;
	background: #ffffff;
	border: 1px solid #dfeaf3;
	border-radius: 8px;
	&__side-info {
		width: 200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		background: #f5fbff;
		border: 1px solid #e7eef4;
		border-radius: 8px 0 0 8px;
	}
	&__side-actions {
		border-left: 1px solid #dfeaf3;
		width: 180px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__content {
		//	width: 100px;
		flex-grow: 1;
	}
	&__info-item {
		//	width: 190px;
		flex-shrink: 0;
	}
	&__title {
		width: calc(100% - 140px);
		flex-grow: 1;
	}
	&__link {
		//	width: 100px;
		align-items: center;
		white-space: nowrap;
		display: flex;
	}
	&__lot-info {
		width: fit-content;
		padding: 8px 20px;
		white-space: nowrap;
		background: #dfeaf3;
		border-radius: 4px;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__button {
		padding: 0 20px;
	}
	&__action {
		width: 100%;
		justify-content: flex-start;
	}
}

.tender-item {
	display: flex;
	background: #ffffff;
	border: 1px solid #dfeaf3;
	border-radius: 8px;
	&__side-info {
		width: 200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		background: #f5fbff;
		border: 1px solid #e7eef4;
		border-radius: 8px 0 0 8px;
	}
	&__side-actions {
		border-left: 1px solid #dfeaf3;
		width: 180px;
	}
	&__content {
		//	width: 100px;
		flex-grow: 1;
	}
	&__info-item {
		//	width: 190px;
		flex-shrink: 0;
	}
	&__title {
		width: calc(100% - 140px);
		flex-grow: 1;
	}
	&__link {
		//	width: 100px;
		align-items: center;
		white-space: nowrap;
		display: flex;
	}
	&__lot-info {
		width: fit-content;
		padding: 8px 20px;
		white-space: nowrap;
		background: #dfeaf3;
		border-radius: 4px;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__button {
		padding: 0 20px;
	}
	&__action {
		width: 100%;
		justify-content: flex-start;
	}
}
</style>