<template>
	<div
		class="form-label"
		:class="[{ 'form-label--focused': focused }, $attrs.class]"
		:style="{ ...$attrs.style, ...style }"
	>
		<app-text v-if="title" class="form-label__title" size="12" line-height="16">{{ title }}</app-text>
		<template v-if="component === 'input' && inputType">
			<component
				:searchable="searchable"
				:type="inputType"
				:isInvalid="isInvalid"
				:value="value"
				:is="component"
				v-bind="$attrs"
				:translatable="translatable"
				:step="step"
				v-on="$listeners"
				@focus="focused = true"
				@blur="focused = false"
				@inputEnter="enterInput"
				:disabled="isDisabled"
			/>
		</template>
		<template v-else-if="component === 'textarea'">
			<component
				:isInvalid="isInvalid"
				:value="value"
				:is="component"
				v-bind="$attrs"
				:translatable="translatable"
				:step="step"
				v-on="$listeners"
				@focus="focused = true"
				@blur="focused = false"
				@inputEnter="enterInput"
				:disabled="isDisabled"
			/>
		</template>
		<template v-else>
			<component
				:searchable="searchable"
				:isInvalid="isInvalid"
				:value="value"
				:is="component"
				v-bind="$attrs"
				:translatable="translatable"
				:step="step"
				v-on="$listeners"
				@focus="focused = true"
				@blur="focused = false"
				@inputEnter="enterInput"
				:disabled="isDisabled"
			/>
		</template>
	</div>
</template>
<script>
import AppText from "./AppText";
import AppDropdown from "./AppDropdown";
import AppInput from "./AppInput";
import "../../assets/styles/components/form-label.scss";

export default {
	name: "FormLabel",
	components: { AppText, AppDropdown, AppInput },
	props: {
		searchable: {
			type: Boolean,
			default: false,
		},
		translatable: {
			type: Boolean,
			default: false,
		},
		inputType: {
			type: String,
		},
		step: {
			type: String,
			default: "1",
		},
		isInvalid: {
			type: Boolean,
			default: false,
		},
		value: {
			type: [String, Number, Boolean],
			default: "",
		},
		title: {
			type: [String, Number],
			default: "",
		},
		componentType: {
			type: String,
			default: "input",
		},
		width: {
			type: [String, Number],
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
	},
	inheritAttrs: false,
	data() {
		return {
			focused: false,
		};
	},
	methods: {
		enterInput() {
			this.$emit("enterFromInputLabel");
		},
	},
	computed: {
		style() {
			const style = {};
			if (this.width) {
				style["--width"] = this.width + "px";
			}
			return style;
		},
		component() {
			switch (this.componentType) {
				case "dropdown":
					return "AppDropdown";
				case "textarea":
					return "textarea";
				default:
					return "app-input";
			}
		},
	},
};
</script>

<style lang="scss">
.form-label__title {
	margin-bottom: 7px;
}
</style>

